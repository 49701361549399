<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <aside class="page__sidebar">
            <div class="sidebar__header">
              <h1 class="sidebar__title">
                Entidades Filantrópicas
              </h1>
            </div>

            <div class="sidebar__nav">
              <router-link
                class="sidebar__nav--anchor"
                to="/ajudar-entidade"
                title="Ajudar uma entidade"
              >
                <i class="icon icon-heart"></i>
                Ajudar uma entidade
              </router-link>
            </div>
          </aside>

          <div class="page__content">
            <div class="modal__content modal__title strong" v-if="load">
              <mark>Carregando...</mark>
            </div>
            <!-- Erro -->
            <div class="modal__content " v-else-if="error">
              <h4>{{ error }}</h4>
            </div>
            <div v-else class="entities__list">
              <div
                v-for="item in entities"
                :key="item.id"
                class="entities__item"
              >
                <div class="entities-avatar">
                  <img
                    v-if="item.avatar"
                    class="entities__item--brand"
                    :src="$help.image(item.avatar)"
                    :alt="item.name"
                  />
                  <span v-else>
                    {{ item.name[0] }}
                  </span>
                </div>

                <div class="entities__item--content">
                  <h4 class="entities__item--title">{{ item.name }}</h4>

                  <div class="entity__item--entry">
                    {{ item.description | cropcaracter }}
                    <a
                      @click="openModal(item)"
                      href="#"
                      title="Saiba mais"
                      data-modal="modal-info-entity"
                      >Saiba mais</a
                    >
                  </div>
                </div>
              </div>

              <!-- <% } %> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <modal-info
      :modal.sync="modal"
      @change-modal="e => (modal = e)"
      :selectedEntity.sync="selectedEntity"
    ></modal-info>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    entities: [],
    error: null,
    load: true,
    modal: false,
    selectedEntity: {}
  }),
  methods: {
    async getEntities () {
      this.load = true
      this.error = null
      try {
        let url = 'entities/getEntities?page=1'
        url += `&user_id=${this.user.id}`
        const { data } = await this.$http.get(url)
        if (data.data.length) this.entities = data.data
        else this.error = 'Não foi possivel encontrar entidades cadastradas.'
      } catch (error) {
        this.error = error.response.data
      } finally {
        this.load = false
      }
    },
    openModal (item) {
      this.selectedEntity = item
      this.modal = true
    }
  },
  computed: {
    ...mapGetters('user', ['user'])
  },
  mounted () {
    this.getEntities()
  },
  components: {
    modalInfo: () => import('../donations/components/modal-info-entity.vue')
  },
  filters: {
    cropcaracter (val) {
      let posfix = '...'
      const text = val.substr(0, 120)
      if (text[text.length - 1] === '.') posfix = '..'
      return `${text}${posfix}`
    }
  }
}
</script>

<style scoped>
.entities-avatar {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  font-weight: bold;
  background: #47be71;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 26px;
  margin-right: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.entities__item--content {
  width: calc(100% - 91px);
  text-align: justify;
}
</style>
